import React, { useEffect, useState } from "react";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import Footer from "./Footer"; // Import your footer component

const PolicyPage = () => {
  const [categories, setCategories] = useState([]);


  return (
    <div className="tt-magic-cursor goto-top">
      {/* Preloader */}
      

      {/* Header */}
   

      <div style={{ marginTop: "120px" }} className="container">
        <h1>Refund and Cancellation Policy</h1>
        <p>
          1. We regret to inform you that MAAMAAS FOOD AND BEVERAGES does not
          offer refunds under any circumstances. Once a payment has been made,
          it is considered final and non-refundable.
        </p>
        <p>
          2. We encourage all clients to carefully review selections before
          making a payment.
        </p>
        <p>3. Return Policy: No return accepted.</p>

        <div style={{ marginTop: "30px" }}>
    <h1>Shipping Policy</h1>
  </div>
  <br></br>
  <p>
    At Maamaas House, we are committed to providing an exceptional dining
    experience.
  </p>
  <p>
    <strong>Takeaway:</strong> You can place an order for takeaway and enjoy
    our meals at your convenience.
  </p>
  <p>
    <strong>Dine-In:</strong> Join us at Maamaas House to experience our warm
    and inviting atmosphere.
  </p>
  <p>
    <strong>Note:</strong> We do not offer shipping or delivery services.
  </p>
  <p>For any questions, please contact us directly.</p>
</div>

      {/* Footer */}
      <Footer />
    </div>
  );
};

export default PolicyPage;
import { Link } from "react-router-dom";
import icon_banner from "../img/icon-down-arrow.svg";
import CountUp from 'react-countup';
import StackCards from './StackCards';
import BrowseCategories from './BroswerCategory';
import React, { useState } from 'react';



const Home = () => {

  const [selectedOption, setSelectedOption] = useState('');

  const handleButtonClick = (option) => {
    setSelectedOption(option);
    console.log('Selected Option:', option);
    // You can also store the selected option in local storage or send it to an API
    localStorage.setItem('orderType', option); // Store in localStorage
  };

  return (
    <div>
      {/* Home Hero Section Start */}
      <div className="hero parallaxie">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              {/* Section Title Start */}
              <div className="section-title">
                <h3 style={{fontSize:'60px'}} className="wow fadeInUp" data-wow-delay="0.50s">Coming Soon</h3>
                <h1 style={{fontSize:'28px'}} className="text-anime">More Information</h1>
                <p style={{fontSize:'24px'}}>(+91) 9063906314</p>
              </div>
              {/* Section Title End */}

              {/* Hero Content Start */}
              {/* <div className="hero-content wow fadeInUp" data-wow-delay="0.75s">
                <p>Step into Maamaa's House Restaurant, where every meal is a journey back to the heart of home-cooked goodness. 
                  Located in the bustling [Location], our restaurant is a sanctuary for those seeking authentic flavors and warm hospitality.
                </p>
              </div> */}

              {/* Hero Content End */}

              

              {/* Hero Footer Start */}
              {/* <div className="hero-footer">
                <Link to="/menu" className="btn-default btn-book-now wow fadeInUp" data-wow-delay="1s" onClick={() => handleButtonClick('Dine In')}>Dine In</Link>
                <Link to="/menu" className="btn-default btn-order-online wow fadeInUp" data-wow-delay="1.25s"  onClick={() => handleButtonClick('Take Away')}>Take Away</Link>
              </div> */}
              {/* Hero Footer End */}
            </div>
          </div>
        </div>

        

        {/* <div className="down-arrow">
          <Link href="#home-about">
            <img src={icon_banner} alt="Down Arrow" />
          </Link>
        </div> */}
      </div>

    
    {/* <StackCards/>
    <BrowseCategories/> */}
    </div>
  );
};

export default Home;

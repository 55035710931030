import React, { useState, useEffect } from 'react';
import axios from 'axios';
import logo from "../img/icon-dinner.png";
import logo1 from "../img/logo/green-img.PNG";
import logo2 from "../img/logo/red-img.PNG";
import $ from 'jquery';
import Carts from './CartComponent';
import { useNavigate } from "react-router-dom"; 
import CryptoJS from 'crypto-js';
import SHA256 from "crypto-js/sha256";

const Menu = () => {
  const [ads, setAds] = useState([]);
  const navigate = useNavigate();
  const [isCartVisible, setIsCartVisible] = useState(false);
  const [menuItems, setMenuItems] = useState([]);  // Store menu items
  const [categories, setCategories] = useState([]);  // Store categories
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);  // Store selected category for filter
  const [dishDetails, setDishDetails] = useState({});
  const [page, setPage] = useState(1);  // Pagination
  const [hasMore, setHasMore] = useState(true);  // Whether there's more data
  const [loading, setLoading] = useState(true);  // Loading state
  const [isDineIn, setIsDineIn] = useState(true);  
  const [orderType, setOrderType] = useState('');
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768); 
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);  
  useEffect(() => {
    const storedDishDetails = JSON.parse(localStorage.getItem('dishDetails')) || {};
    setDishDetails(storedDishDetails);
    
    const storedCategoryId = localStorage.getItem('selectedCategoryId');
    setSelectedCategoryId(storedCategoryId);
  }, []);

  useEffect(() => {
    $("#cart-items").css({ display: "block", right: '-300px' }).animate({ right: '0' }, 1000);
  }, [isCartVisible]);

  useEffect(() => {
    // Retrieve the stored order type from localStorage
    const storedOrderType = localStorage.getItem('orderType');
    if (storedOrderType) {
      setOrderType(storedOrderType);
    }
  }, []);


  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // You can adjust this threshold based on your design
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  

  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, [selectedCategoryId]);


  const [userLoggedIn, setUserLoggedIn] = useState(() => {
    // Check if the user is logged in from localStorage
    return localStorage.getItem('userLoggedIn') === 'true';
  });

useEffect(() => {
    // Check login status on component mount
    const isLoggedIn = localStorage.getItem('userLoggedIn'); // Get login status from localStorage
    setUserLoggedIn(isLoggedIn);
}, []);

  useEffect(() => {
    const storedDishDetails = JSON.parse(localStorage.getItem('dishDetails')) || {};
    setDishDetails(storedDishDetails);
  }, []);


  const handleToggle = () => {
    setIsDineIn(!isDineIn); // Toggle between Dine In and Take Away
  };
  

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get('http://10.10.20.9:7007/Mamaswebsite-0.0.1-SNAPSHOT/categories/getall');
        setCategories(response.data);
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };

    fetchCategories();
  }, []);


  useEffect(() => {
    const fetchAds = async () => {
      try {
        const response = await axios.get('http://10.10.20.9:7007/Mamaswebsite-0.0.1-SNAPSHOT/advertaisment/getall');
        setAds(response.data);
      } catch (error) {
        console.error('Error fetching advertisements:', error);
      }
    };

    fetchAds();
  }, []);

 // Fetch menu items based on category
 const fetchMenuItems = async (page) => {
  
  // if (loading || !hasMore) return;  // Prevent multiple requests while loading or if no more data

  let url = `http://10.10.20.9:7007/Mamaswebsite-0.0.1-SNAPSHOT/get/alldishes/${page}`;


  console.log('Fetching for category:', selectedCategoryId);
  // Uncomment for category-specific data if needed
  if (selectedCategoryId) {
    url = `http://10.10.20.9:7007/Mamaswebsite-0.0.1-SNAPSHOT/get/list/${selectedCategoryId}`;
  }

  try {
    setLoading(true);  // Start loading

    const response = await axios.get(url);
    console.log('Fetched data:', response.data);  // Debugging to check response data

    if (response.data && response.data.length > 0) {
      setMenuItems((prevItems) => {
        if (page > 1) {
          return [...prevItems, ...response.data];  // Append for subsequent pages
        }
        return response.data;  // Set first page items
      });
      setHasMore(true);  // More data is available
    } else {
      setHasMore(false);  // No more data to load
    }
  } catch (error) {
    console.error('Error fetching menu items:', error);
  } finally {
    setLoading(false);  // Stop loading
  }
};

// Fetch the first page on initial load or category change
useEffect(() => {
  console.log('Category or component mounted, fetching page 1');
  fetchMenuItems(1);  // Fetch the first page when category changes or initially
  // setCurrentPage(1);  // Reset to page 1 on category change
}, [selectedCategoryId]);  // Trigger when selectedCategoryId changes

// Fetch additional pages based on the page number
useEffect(() => {
  if (currentPage > 1 && hasMore) {
    console.log(`Fetching page: ${currentPage}`);
    fetchMenuItems(currentPage);  // Fetch the next page
  }
}, [currentPage]);  // Trigger when page number changes

// Auto-fetch next page every 5 seconds if there is more data
useEffect(() => {
  if (hasMore) {
    const intervalId = setInterval(() => {
      console.log('Incrementing page to fetch more items');
      setCurrentPage((prevPage) => prevPage + 1);  // Increment page to load next set of items
    }, 500);  // Set interval to 5 seconds (or your preferred duration)

    // Cleanup the interval when component unmounts or hasMore becomes false
    return () => clearInterval(intervalId);
  }
}, [hasMore]);  // Only run if hasMore is true
// Only keep the interval if there are more items to load

  // Handle category selection
  const handleCategoryIdClick = (categoryId) => {
    if (selectedCategoryId !== categoryId) {
      setMenuItems([]); // Clear previous items before applying a new filter
      setLoading(true); // Show loading state while fetching new items
      setPage(1); // Reset page to 1
      setHasMore(true); // Reset the pagination
      setSelectedCategoryId(categoryId); // Set the selected category
      setSelectedCategoryId(categoryId);
    if (isMobile) {
      setDropdownVisible(false); // Close dropdown on category click in mobile view
    }
    }
  };
  


const initiatePayment = async () => {
  setLoading(true);

  // Payment and transaction details
  
      // Razorpay Payment Options for paid courses
      var options = {
        key: "rzp_live_8ge9LKQB4bNxkg",
        key_secret: "eaHmzOnTh2AqXLFRFWavB29c",
        amount: 1 * 100, // Convert price to paise
        currency: "INR",
        payment_capture: true,
        name: "International Institute Of Automate",
        handler: async function (response) {
          alert(
            "Thanks For Your Interest \n Your Payment has been successfully completed"
          );
  
          // Call API to update payment status
         
        },
        notes: {
          address: "International Institute Of Automate",
        },
        theme: {
          color: "#3399cc",
        },
      };
  
      var pay = new window.Razorpay(options);
      pay.open();
    } 
  


  
  
  const updateQuantity = (dishId, delta) => {
    setDishDetails((prevItems) => {
      const updatedItems = { ...prevItems };
      if (updatedItems[dishId]) {
        const currentQuantity = updatedItems[dishId].quantity;
        const newQuantity = currentQuantity + delta;
        if (newQuantity <= 0) {
          delete updatedItems[dishId];
        } else {
          updatedItems[dishId].quantity = newQuantity;
        }
      }
      return updatedItems;
    });
  };

  const calculateTotal = () => {
    const subtotal = Object.values(dishDetails).reduce((acc, item) => acc + item.price * item.quantity, 0);
    const cgst = subtotal * 0.09;
    const sgst = subtotal * 0.09;
    const grandTotal = subtotal + cgst + sgst;
    return { subtotal, cgst, sgst, grandTotal };
  };

  const { subtotal, cgst, sgst, grandTotal } = calculateTotal();


 


  const incrementQuantity = (dishId, name, price) => {
    setDishDetails(prev => {
      const newDetails = {
        ...prev,
        [dishId]: {
          quantity: (prev[dishId]?.quantity || 0) + 1,
          name: name || prev[dishId]?.categoryName || prev[dishId]?.subCategory,
          price: price || prev[dishId]?.price,
          dishId: dishId
        }
      };
  
      console.log(newDetails)
      localStorage.setItem('dishDetails', JSON.stringify(newDetails)); // Immediately save to localStorage
      return newDetails;
    });
    
    setIsCartVisible(true);  // Ensure cart is visible after adding
  };
  
  const decrementQuantity = (dishId) => {
    setDishDetails(prev => {
      const currentQuantity = prev[dishId]?.quantity || 0;
      let newDetails;
  
      if (currentQuantity <= 1) {
        // Remove the item from cart if quantity goes below 1
        const { [dishId]: _, ...remainingItems } = prev;
        newDetails = remainingItems;
      } else {
        // Otherwise, decrement the quantity
        newDetails = {
          ...prev,
          [dishId]: {
            ...prev[dishId],
            quantity: currentQuantity - 1
          }
        };
      }
  
      localStorage.setItem('dishDetails', JSON.stringify(newDetails));  // Immediately save to localStorage
  
      const hasItems = Object.values(newDetails).some(item => item.quantity > 0);
      if (!hasItems) {
        setIsCartVisible(false);
      }
  
      return newDetails;
    });
  };
  

// Sync `dishDetails` from localStorage immediately after page refresh or state updates



  return (
    <div className="menu-container">
     {!isMobile && (
        <div className="categories-sidebar">
          <h3>Categories</h3>
          <ul>
            {categories.map((category) => (
              <li
                key={category.categoryId}
                onClick={() => handleCategoryIdClick(category.categoryId)}
                style={{
                  cursor: 'pointer',
                  fontWeight: selectedCategoryId === category.categoryId ? 'bold' : 'normal',
                }}
              >
                <img src={logo} alt="Logo" height="20px" />
                {category.categoryName}
              </li>
            ))}
          </ul>
        </div>
      )}

{isMobile && (
    <div className="categories-dropdown">
      <h3 onClick={() => setDropdownVisible(!isDropdownVisible)} style={{ cursor: 'pointer' }}>
        Categories {isDropdownVisible ? '▲' : '▼'}
      </h3>

      {isDropdownVisible && (
        <ul>
          {categories.map((category) => (
            <li
              key={category.categoryId}
              onClick={() => handleCategoryIdClick(category.categoryId)}
              style={{
                cursor: 'pointer',
                fontWeight: selectedCategoryId === category.categoryId ? 'bold' : 'normal',
              }}
            >
              <img src={logo} alt="Logo" height="20px" />
              {category.categoryName}
            </li>
          ))}
        </ul>
      )}
      </div>
  )}



      <div className="menu-container1">
      <div className="menu-items">
        {menuItems.map(item => (
          <div className="menu-item-card" key={item.dishId}>
            <img src={`data:image/png;base64,${item.image}`} alt={item.name} className="menu-item-image" />
            <h4 className="menu-item-name">{item.categoryName || item.subCategory}</h4>
            <p className="menu-item-price">₹ {item.price}</p>
            <div className="menu-item-stock-container">
              <p className="menu-item-stock">{item.stock ? "In Stock" : "Out of Stock"}</p>
              <span className={`dish-icon ${item.tag === 'Veg' ? 'veg' : 'non-veg'}`}>
                {item.tag === 'Veg' ? (
                  <img src={logo1} alt="Veg" className="dish-icon-img" />
                ) : (
                  <img src={logo2} alt="Non-Veg" className="dish-icon-img" />
                )}
              </span>
            </div>

            {item.stock ? (
              dishDetails[item.dishId]?.quantity > 0 ? (
                <div className="quantity-controls">
                  <button className="decrement-button" onClick={() => decrementQuantity(item.dishId)}>-</button>
                  <span className="quantity-count">{dishDetails[item.dishId]?.quantity || 0}</span>
                  <button className="increment-button" onClick={() => incrementQuantity(item.dishId, item.categoryName || item.subCategory, item.price)}>+</button>
                </div>
              ) : (
                <button className="add-button" onClick={() => incrementQuantity(item.dishId, item.categoryName || item.subCategory, item.price)}>Add</button>
              )
            ) : (
              <button className="add-button" disabled>Out of Stock</button>
            )}
          </div>
        ))}
      </div>
      </div>

      <div className="col-lg-3 col-md-6 items-container" style={{ paddingBottom: '10px', position: 'fixed', right: '1%' }}>
        <div className="marquee-container" style={{ overflow: 'hidden', width: '100%' }}>
          <div className="marquee" style={{ display: 'flex', flexDirection: 'column', animation: 'marquee 15s linear infinite' }}>
            {ads.map(item => (
              <div key={item.id} className="menu-item-box item" style={{ width: '90%', display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '10px', backgroundColor: '#EE4B2E', marginBottom: '20px' }}>
                <div className="menu-item" style={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#ee4b2e' }}>
                  <div className="menu-item-img">
                    <div className="image-anime">
                      <img src={`data:image/png;base64,${item.image}`} alt="Advertisement" style={{ maxHeight: '180px', maxWidth: '100%' }} />
                    </div>
                  </div>
                </div>
                <p style={{ fontWeight: 'bold', color: '#fff', textAlign: 'center' }}>{item.description}</p>
              </div>
            ))}
          </div>

        
           <div id="cart-items" className="cart-container">
           <h5 className="cart-title">View Cart</h5>
           <table className="cart-table">
               <thead>
                   <tr>
                       <th>SNO</th>
                       <th>ITEM</th>
                       <th>PRICE</th>
                       <th>QTY</th>
                       <th>TOTAL</th>
                   </tr>
               </thead>
               <tbody>
                   {Object.values(dishDetails).map((item, index) => (
                       <tr key={index}>
                           <td>{index + 1}</td>
                           <td>{item.name}</td>
                           <td>₹{item.price}</td>
                           <td>
                               <div className="qty-container">
                                   <button className="qty-button" onClick={() => updateQuantity(item.dishId, -1)}>-</button>
                                   <span className="qty-count">{item.quantity}</span>
                                   <button className="qty-button" onClick={() => updateQuantity(item.dishId, 1)}>+</button>
                               </div>
                           </td>
                           <td>₹{(item.price * item.quantity).toFixed(2)}</td>
                       </tr>
                   ))}
               </tbody>
           </table>
     
           {/* Display totals */}
           <div className="cart-totals">
               <p>Total: <span>₹{subtotal.toFixed(2)}</span></p>
               <p>CGST (9%): <span>₹{cgst.toFixed(2)}</span></p>
               <p>SGST (9%): <span>₹{sgst.toFixed(2)}</span></p>
               <p>Grand Total: <span>₹{grandTotal.toFixed(2)}</span></p>
     
               <div className="cart-options">
                   {/* <ToggleButton onToggle={(dineIn) => setIsDineIn(dineIn)} /> */}
                   <label className="switch">
             <input
               type="checkbox"
               className="toggleSwitch"
               id="service"
               checked={isDineIn}
               onChange={handleToggle} // Toggle the Dine In/Take Away state
             />
             <span className="slider"></span>
           </label>
           <p
        className="status"
        id="status-1"
        style={{ marginTop: '-10px', marginRight: '130px' }}
      >
        {orderType ? orderType : 'No selection'} {/* Show either 'Dine In' or 'Take Away' */}
      </p>

                   <button className="checkout-button" onClick={initiatePayment}>
                       {"Pay Now"}
                   </button>
               </div>
           </div>
         </div>
         
         
        </div>
      </div>
    </div>
  );
};

export default Menu
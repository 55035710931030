import React, { useEffect, useState } from "react";
import axios from "axios";
import Footer from "./Footer";
import "bootstrap/dist/css/bootstrap.min.css";


const ShippingPolicy = () => {
  const [categories, setCategories] = useState([]);



  return (
    <div className="tt-magic-cursor goto-top">
      {/* Preloader */}
      

      {/* Magic Cursor */}
      <div id="magic-cursor">
        <div id="ball"></div>
      </div>

      {/* Header */}
     

      <div style={{ marginTop: "120px" }}>
        <h1>Shipping Policy</h1>
      </div>

      <p>
        At Maamaas House, we are committed to providing you with an exceptional
        dining experience. Currently, we offer the following options for
        enjoying our delicious dishes:
      </p>

      <p>
        <strong>Takeaway:</strong> You can place an order for takeaway and enjoy our meals at
        your convenience. Simply place your order online or call us, and we’ll
        have your meal ready for pickup.
      </p>

      <p>
        <strong>Dine-In:</strong> Join us at Maamaas House to experience our warm and
        inviting dining atmosphere. We look forward to serving you in person and
        providing you with a memorable meal.
      </p>

      <p>
        <strong>Please Note:</strong> We do not offer shipping or delivery services at this
        time. We apologize for any inconvenience this may cause and appreciate
        your understanding.
      </p>

      <p>For any questions or to place an order, please contact us directly.</p>

      <p>Thank you for choosing Maamaas House!</p>

      {/* Footer */}
      <Footer />
    </div>
  );
};

export default ShippingPolicy;
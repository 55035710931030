import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faArrowUp, faLocationDot, faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import logo from "../img/MAAMAAS_LOGO.png"; // Ensure this path is correct

const Footer = () => {
  const phoneNumber = '9063906314';

  const scrollToTop = (event) => {
    event.preventDefault();
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <footer className="footer bg-dark text-white"> {/* Apply background and text color */}
      <a href={`https://wa.me/${phoneNumber}`} className="whatsapp-button">
        <img src="https://upload.wikimedia.org/wikipedia/commons/6/6b/WhatsApp.svg" alt="WhatsApp" />
      </a>

      {/* Mega Footer */}
      <div className="footer-mega">
        <div className="container">
          <div className="row">
            {/* Footer About */}
            <div className="col-lg-4 col-md-12">
              <div className="footer-about text-center text-white"> {/* Make text white */}
                <img src={logo} alt="Logo" height="80px" />
                <p className="mb-0 mt-2">
                  "Bringing you the flavors of tradition, straight from Maamaa's Houses. Where each recipe whispers
                  tales of generations past. Savor the essence of heritage in every delightful bite."
                </p>
              </div>
            </div>

            {/* Footer Menu */}
            <div className="col-lg-5 col-md-7 d-flex justify-content-between">
              <div className="footer-links text-white">
                <h2>Quick Links</h2>
                <ul>
                  <li><a href="index.php" className="text-white"><FontAwesomeIcon icon={faArrowRight} /> Home</a></li>
                  <li><a href="menu.php" className="text-white"><FontAwesomeIcon icon={faArrowRight} /> Menu</a></li>
                  <li><a href="contact.php" className="text-white"><FontAwesomeIcon icon={faArrowRight} /> Contact Us</a></li>
                </ul>
              </div>
              <div className="footer-links text-white">
                <h2>Policies</h2>
                <ul>
                  <li><a href="/priv" className="text-white"><FontAwesomeIcon icon={faArrowRight} /> Privacy Policy</a></li>
                  <li><a href="/ship" className="text-white"><FontAwesomeIcon icon={faArrowRight} /> Shipping Policy</a></li>
                  <li><a href="/priv1" className="text-white"><FontAwesomeIcon icon={faArrowRight} /> Refund and Cancellation Policy</a></li>
                  <li><a href="/term" className="text-white"><FontAwesomeIcon icon={faArrowRight} /> Terms & Conditions</a></li>
                </ul>
              </div>
            </div>

            {/* Footer Contact */}
            <div className="col-lg-3 col-md-5">
              <div className="footer-contact text-white">
                <h2>Contact Now</h2>
                <ul className="list-unstyled">
                  <li className="d-flex align-items-start">
                    <FontAwesomeIcon icon={faLocationDot} className="me-2" />
                    <a href="https://www.google.com/maps" target="_blank" rel="noopener noreferrer" className="text-white">
                      KPHP colony, Kukatpally, Hyderabad, Telangana-500072.
                    </a>
                  </li>
                  <li className="d-flex align-items-start mt-2">
                    <FontAwesomeIcon icon={faPhone} className="me-2" />
                    <a href="tel:+919063906314" className="text-white">(+91) 9063906314</a>
                  </li>
                  <li className="d-flex align-items-start mt-2">
                    <FontAwesomeIcon icon={faEnvelope} className="me-2" />
                    <a href="mailto:maamaashouses@gmail.com" className="text-white">maamaashouses@gmail.com</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          {/* Footer Copyright */}
          <div className="footer-copyright mt-4 text-white">
            <div className="row align-items-center">
              <div className="col-md-11 text-center">
                <p>Copyright © 2024 <strong>Maamaas House.</strong> All rights reserved.</p>
              </div>
              <div className="col-md-1 text-end">
                <a href="#hero-section" className="footer-btn text-white" id="scrollToTopBtn" onClick={scrollToTop}>
                  <FontAwesomeIcon icon={faArrowUp} />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
